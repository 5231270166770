import MajaalApi from "../../api/MajaalApi.js";

export const namespaced = true

export const state = {
    apiPath: 'favorites',
    collections: [],
    collection: {},
    items: [],
    defaultItems: [],
    count: 0,
    defaultCount: 0,
    meta: {},
}

export const mutations = {
    SET_ITEMS(state, items) {
        state.items = items.data;
        state.meta = items.meta;
    },
    SET_DEFAULT_CONTAINER_ITEMS(state, items) {
        state.defaultItems = items.data;
        // state.meta = items.meta;
    },
    SET_COLLECTIONS(state, items) {
        state.collections = items.data;
        state.defaultCount = items.no_container_count;
    },
    SET_COLLECTION(state, items) {
        state.collection = items.data;
    },
    SET_COUNT(state, value) {
        state.count = value;
    }
}


export const actions = {

    getItems({ state, commit }, { filters }) {
        filters = { ...filters }

        return MajaalApi
            .index(state.apiPath, filters)
            .then(response => {
                commit('SET_ITEMS', response.data)
            })
            .catch(err => {
                if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw err
            })
    },
    getCollections({ commit }, { filters }) {
        filters = { ...filters }

        return MajaalApi
            .index(`favorite-containers`, filters)
            .then(response => {
                commit('SET_COLLECTIONS', response.data)
            })
            .catch(err => {
                if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw err
            })
    },
    getCollection({ commit }, { id }) {

        return MajaalApi
            .show(id, `favorite-containers`)
            .then(response => {
                commit('SET_COLLECTION', response.data)
            })
            .catch(err => {
                if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw err
            })
    },
    editCollection({ commit }, { id, body }) {

        return MajaalApi
            .update(id, `favorite-containers`, body)
            .then(response => {
                return response
            })
            .catch(err => {
                if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw err
            })
    },
    deleteCollection({ commit }, { id }) {

        return MajaalApi
            .delete(id, `favorite-containers`)
            .then(response => {
                return response
            })
            .catch(err => {
                if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw err
            })
    },
    createCollection({ commit }, { body }) {

        return MajaalApi
            .store(`favorite-containers`, body)
            .then(response => {
                return response
            })
            .catch(err => {
                if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw err
            })
    },
    addToCollections({ commit }, { body, id }) {
        // items/:id/favorites


        return MajaalApi
            .store(`items/${id}/favorites`, body)
            .then(response => {
                return response
            })
            .catch(err => {
                if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw err
            })
    },

    getCount({ state, commit }) {

        return MajaalApi
            .index(`${state.apiPath}/count`)
            .then(response => {
                commit('SET_COUNT', response.data.count)
            })
            .catch(err => {
                if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw err
            })
    },
    // favorites/containers?container_id=null or container_id
    getCollectionItems({ state, commit }, { filters, defaultItems = false }) {
        filters = { ...filters }

        return MajaalApi
            .index(`${state.apiPath}/containers`, filters)
            .then(response => {
                if (defaultItems)
                    commit('SET_DEFAULT_CONTAINER_ITEMS', response.data)
                else
                    commit('SET_ITEMS', response.data)
            })
            .catch(err => {
                if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw err
            })
    },
    updateItem({ commit, state }, { id, body }) {
        return MajaalApi.update(id, state.apiPath, body)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                if (error.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw error;
            });
    },
}