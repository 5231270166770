import Vue from 'vue';
import Vuex from 'vuex';

import * as authModule from './modules/auth.js';
import * as itemsModule from './modules/items.js';
import * as cartModule from './modules/cart.js';
import * as favoritesModule from './modules/favorites.js';
import * as bannersModule from "./modules/banners.js";


Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		authModule,
		itemsModule,
		cartModule,
		favoritesModule,
		bannersModule

	},
});

export default store

