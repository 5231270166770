import NmhApi from "../../api/MajaalApi.js"
import router from '../../router'
import axios from 'axios'

export const namespaced = true

export const state = {
	apiPath: 'register',
	token: null,
	user: null,
	username: null,
	professions: null,
	ads: [],
	showAds: false,
}

export const mutations = {
	SET_USER_TOKEN(state, userToken) {
		state.token = userToken;
		localStorage.setItem("MajaalToken", state.token);
	},
	SET_USER_DATA(state, userData) {
		state.user = userData;
		localStorage.setItem("MajaalUser", JSON.stringify(userData));
	},
	SET_PROFESSIONS(state, data) {
		state.professions = data.professions;
	},
	LOGOUT_USER(state) {
		state.token = null;
		state.user = null;
		window.localStorage.removeItem("MajaalToken");
		window.localStorage.removeItem("MajaalUser");
		return router.push("/login");
	},
	setValid(state, value) {
		state.user = value.data;
		state.username = state.user?.firstName + " " + state.user?.lastName;
	},
	SET_ADS(state, value) {
		state.ads = value.data?.ads;
		if (state.ads?.length > 0)
			state.showAds = true;
	},
	hideAds(state) {
		state.showAds = false;
	},
}

export const actions = {

	login({ commit }, { body }) {

		return NmhApi.store('login', body)
			.then(response => {
				commit('SET_USER_DATA', response.data.data.user);
				commit('SET_USER_TOKEN', `bearer ${response.data.data.token.token}`);
				return Promise.resolve(0);
			}).catch(err => {
				throw err
			})
	},

	signUp({ state }, { body }) {
		return NmhApi.store(state.apiPath, body)
			.then((response) => {
				return response;
			}).catch(err => {
				throw err
			})
	},
	logout({ commit }, { body }) {
		return NmhApi.store("logout", body)
			.then((response) => {
				commit('LOGOUT_USER');
				return response;
			}).catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	validateToken({ commit }) {
		return axios.get(`${NmhApi.baseDomain}/api/v1/auth/me`, {
			headers: {
				'Authorization': localStorage.getItem("MajaalToken"),
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		})
			.then((res) => {
				commit('setValid', res.data)
			})
			.catch((err) => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err;
			});
	},
	getProfessions({ commit }) {
		return NmhApi.index("professions")
			.then((response) => {
				commit('SET_PROFESSIONS', response.data);
				return response;
			}).catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	getAds({ commit }) {
		return NmhApi.index("ads")
			.then((response) => {
				commit('SET_ADS', response.data);
				return response;
			}).catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	requestPassReset({ commit }, { body }) {
		return NmhApi.store("password/reset/request", body)
			.then((response) => {
				// commit('SET_ADS', response.data);
				return response;
			}).catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	resetPassword({ commit }, { body }) {
		return NmhApi.update("", "password/reset", body)
			.then((response) => {
				return response;
			}).catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
}
