<template>
  <div>
    <v-app
      id="myApp"
      v-if="
        $router.currentRoute.name != 'Login' &&
        $router.currentRoute.name != 'SignUp' &&
        $router.currentRoute.name != 'Home' &&
        $router.currentRoute.name != 'ResetPassword'
      "
    >
      <v-app-bar app elevation="0" color="white" height="90">
        <v-container class="d-flex mt-3 flex-row-reverse align-center">
          <v-col cols="8" v-if="$vuetify.breakpoint.width > 857" class="pa-0 ma-0 d-flex">
            <v-col cols="8" class="pa-0 ma-0 d-flex align-center">
              <v-badge
                color="red accent-3"
                class="mx-8"
                offset-x="10"
                offset-y="10"
                :content="cartCount"
                :value="cartCount == 0 ? false : true"
              >
                <v-btn
                  to="/cart"
                  color="black"
                  outlined
                  style="color: white; letter-spacing: 0px"
                >
                  <span class="mx-5">{{ $t("nav.cart") }}</span>
                  <v-icon>mdi-cart</v-icon>
                </v-btn>
              </v-badge>

              <v-btn
                to="/favorites"
                color="black"
                outlined
                style="color: white; letter-spacing: 0px"
              >
                <span class="mx-5">{{ $t("nav.favorites") }}</span>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn
                to="/items"
                color="black"
                outlined
                class="mx-8"
                style="color: white; letter-spacing: 0px"
              >
                <span class="mx-5">{{ $t("nav.home") }}</span>
                <v-icon>mdi-home</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-row class="hidden-xs-only" align="center" justify="end">
                <v-card
                  color="transparent"
                  class="d-flex align-center justify-space-around"
                  flat
                >
                  <v-col cols="7" sm="2" md="1" class="py-0">
                    <v-avatar size="36px" color="grey">
                      <v-icon>mdi-account</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col
                    :class="`px-0 ${$i18n.locale == 'ar' ? 'pr' : 'pl'}-6 py-0`"
                    cols="auto"
                  >
                    <strong style="font-size: 0.8rem">{{ username }}</strong>
                  </v-col>
                  <v-col class="pl-0 py-0" cols="auto">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <v-btn
                              text
                              depressed
                              style="letter-spacing: 0px"
                              :ripple="false"
                              plain
                              @click="logout"
                              >{{ $t("nav.logout") }}</v-btn
                            >
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-card>
              </v-row>
            </v-col>
          </v-col>
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.width <= 857"
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>

          <v-spacer></v-spacer>
          <LocaleSwitcher />

          <div class="d-flex align-center">
            <v-col class="px-0" cols="8" sm="8" md="10" lg="12">
              <v-img
                alt="Majaal Logo"
                class="shrink mr-2"
                contain
                src="./assets/images/bim.svg"
                transition="scale-transition"
                width="140"
                @click="$router.push('/items')"
                style="cursor: pointer"
              />
            </v-col>
          </div>
        </v-container>
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        :right="$i18n.locale == 'ar' ? false : true"
        app
        v-if="drawer"
      >
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-avatar color="grey">
              <v-icon>mdi-account</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ username }}</v-list-item-title>
              <v-list-item-subtitle>Logged In</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("nav.logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider dark></v-divider>
        <v-list>
          <v-list-item link to="/items">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("nav.home") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/cart">
            <v-list-item-icon>
              <v-icon>mdi-cart</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                >{{ $t("nav.cart") }}
                <v-badge
                  color="red accent-3"
                  class="mx-3"
                  :content="cartCount"
                  :value="cartCount == 0 ? false : true"
                ></v-badge
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/favorites">
            <v-list-item-icon>
              <v-icon>mdi-heart</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("nav.favorites") }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main fluid>
        <v-container class="pt-9">
          <v-scroll-x-transition mode="out-in" :hide-on-leave="true">
            <router-view :key="$route.fullPath" />
          </v-scroll-x-transition>
        </v-container>
        <ContactFab />
      </v-main>
      <v-footer dark color="black" class="d-flex justify-center mt-4">
        <div class="text-center col-12">
          {{ new Date().getFullYear() }} —
          <strong style="color: white"> {{ $t("footer.majaal") }}</strong>
        </div>

        <div class="text-center col-12 d-flex justify-center align-center">
          <strong style="color: white">
            {{ $t("footer.crafted") }}
          </strong>
          <a class="px-2" href="https://artisans.ly" target="_blank"> Artisans.ly </a>
        </div>
      </v-footer>
    </v-app>

    <!-- 
      H  O  M  E 
    -->

    <v-app id="myApp" v-if="$router.currentRoute.name == 'Home'" style="z-index: 9999">
      <v-app-bar app elevation="0" color="white">
        <v-container class="d-flex mt-3 flex-row-reverse align-center">
          <v-col cols="auto" v-if="$vuetify.breakpoint.width > 857">
            <v-btn
              block
              color="black"
              large

              style="color: white; letter-spacing: 0px"
              to="/login"
            >
              <span class="mr-2">{{ $t("nav.login") }}</span>
            </v-btn>
          </v-col>
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.width <= 857"
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <LocaleSwitcher />
          <div class="d-flex align-center">
            <v-col class="px-0" cols="8" sm="8" md="10" lg="12">
              <v-img
                alt="Majaal Logo"
                class="shrink mr-2"
                contain
                src="./assets/images/bim.svg"
                transition="scale-transition"
                width="140"
                @click="$router.push('/home')"
                style="cursor: pointer"
              />
            </v-col>
          </div>
        </v-container>
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        :right="$i18n.locale == 'ar' ? false : true"
        app
        v-if="drawer"
      >
        <v-list>
          <v-list-item link to="/login">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("nav.login") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main fluid>
        <v-container class="pt-9">
          <v-scroll-x-transition mode="out-in" :hide-on-leave="true">
            <router-view :key="$route.fullPath" />
          </v-scroll-x-transition>
        </v-container>
        <ContactFab />
      </v-main>
      <v-footer dark color="black" class="d-flex justify-center mt-4">
        <div class="text-center col-12">
          {{ new Date().getFullYear() }} —
          <strong style="color: white"> {{ $t("footer.majaal") }}</strong>
        </div>

        <div class="text-center col-12 d-flex justify-center align-center">
          <strong style="color: white">
            {{ $t("footer.crafted") }}
          </strong>
          <a class="px-2" href="https://artisans.ly" target="_blank"> Artisans.ly </a>
        </div>
      </v-footer>
    </v-app>

    <!-- 
      L  O  G  I  N 
    -->

    <v-app v-if="$router.currentRoute.name == 'Login'">
      <v-navigation-drawer
        permanant
        app
        :right="$i18n.locale == 'ar' ? true : false"
        color="black"
        width="30%"
        class="sidebar"
      >
        <div class="d-flex" style="height: 80%">
          <div class="pa-2 mx-auto my-auto" style="text-align: center">
            <h1 style="width: 100%; font-size: 1.6rem; padding-bottom: 10px">
              {{ $t("loginPage.ctaHeading") }}
            </h1>
            <p style="width: 80%" class="mx-auto">
              {{ $t("loginPage.ctaSub") }}
            </p>
            <v-btn
              outlined
              rounded
              to="signup"
              color="white"
              style="letter-spacing: 0px"
              >{{ $t("loginPage.createAccount") }}</v-btn
            >
          </div>
        </div>
      </v-navigation-drawer>
      <v-app-bar class="d-flex flex-row-reverse" app elevation="0" color="transparent">
        <LocaleSwitcher />
      </v-app-bar>

      <v-main>
        <div class="d-flex align-center justify-center">
          <v-img
            alt="Majaal Logo"
            class="shrink ma-4"
            contain
            src="@/assets/images/bim.svg"
            transition="scale-transition"
            @click="$router.push('/home')"
            width="130"
            style="cursor: pointer"
          />
        </div>
        <router-view :key="$route.fullPath" />
        <ContactFab />
      </v-main>
    </v-app>
    <!-- 
      S  I  G  N   U  P 
    -->
    <v-app v-if="$router.currentRoute.name == 'SignUp'">
      <ContactFab style="z-index: 999" />

      <v-navigation-drawer
        permanant
        app
        :right="$i18n.locale == 'ar' ? false : true"
        color="black"
        width="30%"
        class="sidebar"
      >
        <div class="d-flex" style="height: 80%">
          <div class="pa-2 mx-auto my-auto" style="text-align: center">
            <h1 style="width: 100%; font-size: 2rem; padding-bottom: 10px">
              {{ $t("signUpPage.ctaHeading") }}
            </h1>
            <p style="width: 60%" class="mx-auto">
              {{ $t("signUpPage.ctaSub") }}
            </p>
            <v-btn
              outlined
              rounded
              to="login"
              color="white"
              style="letter-spacing: 0px"
              >{{ $t("signUpPage.login") }}</v-btn
            >
          </div>
        </div>
      </v-navigation-drawer>
      <v-app-bar class="d-flex" app elevation="0" color="transparent">
        <LocaleSwitcher />
      </v-app-bar>
      <v-main>
        <div class="d-flex align-center justify-center">
          <v-img
            alt="Majaal Logo"
            class="shrink ma-4"
            contain
            src="@/assets/images/bim.svg"
            transition="scale-transition"
            @click="$router.push('/home')"
            width="130"
            style="cursor: pointer"
          />
        </div>
        <router-view :key="$route.fullPath" />
      </v-main>
    </v-app>

    <v-app v-if="$router.currentRoute.name == 'ResetPassword'">
      <v-app-bar
        class="d-flex flex-row-reverse"
        app
        elevation="0"
        color="transparent"
      >
        <LocaleSwitcher />
      </v-app-bar>

      <v-main>
        <div class="d-flex align-center justify-center">
          <v-img
            alt="Majaal Logo"
            class="shrink ma-4"
            contain
            src="@/assets/images/bim.svg"
            transition="scale-transition"
            @click="$router.push('/home')"
            width="130"
            style="cursor: pointer"
          />
        </div>
        <router-view :key="$route.fullPath" />
        <ContactFab />
      </v-main>
    </v-app>
    <AdsDialog :dialog="showAds" @close="$store.commit('authModule/hideAds')" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import LocaleSwitcher from "@/components/LocaleSwitcher";
import ContactFab from "@/components/ContactFab";
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle,
} from "@/util/i18n/document";
import AdsDialog from "./components/AdsDialog.vue";

export default {
  name: "App",
  components: { LocaleSwitcher, ContactFab, AdsDialog },
  data() {
    return {
      logoutLoading: false,
      loggedIn: true,
      drawer: false,
    };
  },

  computed: {
    ...mapState({
      cartCount: (state) => state.cartModule.count,
      token: (state) => state.authModule.token,
      username: (state) => state.authModule.username,
      showAds: (state) => state.authModule.showAds,
    }),
  },
  mounted() {
    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        this.$vuetify.rtl = newLocale === "ar" ? true : false;
        setDocumentLang(newLocale);
        setDocumentDirectionPerLocale(newLocale);
        setDocumentTitle(this.$t("app.title"));
      },
      { immediate: true }
    );
  },

  created() {},
  methods: {
    logout() {
      this.logoutLoading = true;

      this.$store
        .dispatch("authModule/logout", {
          body: {},
        })
        .then(() => {
          this.$router.push("/home");
        })

        .finally(() => {
          this.logoutLoading = false;
          this.drawer = false;
        });
    },
    getCartCount() {
      this.$store.dispatch("cartModule/getCount", {});
    },
    // getFavCount() {
    //   this.$store.dispatch("favoritesModule/getCount", {});
    // },
    validateToken() {
      this.$store.dispatch("authModule/validateToken");
    },
  },
};
</script>

<style scoped>
.sidebar {
  color: white;
  background-image: url("./assets/images/pattern.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@font-face {
  font-family: "Readex";
  src: local("Readex"),
    url(./assets/Readex_Pro/ReadexPro-VariableFont_wght.ttf) format("truetype");
}

/* #myApp {
  font-family: "Readex", "Poppins" !important;
} */
</style>
