import MajaalApi from "../../api/MajaalApi.js";

export const namespaced = true

export const state = {
    apiPath: 'carts',
    items: [],
    count: 0,
    report: {}

}

export const mutations = {
    SET_ITEMS(state, items) {
        state.items = items.data;
    },
    SET_COUNT(state, value) {
        state.count = value;
    },
    SET_REPORT(state, value) {
        state.report = value;
    }
}


export const actions = {

    getItems({ state, commit }, { filters }) {
        filters = { ...filters }

        return MajaalApi
            .index(state.apiPath, filters)
            .then(response => {
                commit('SET_ITEMS', response.data)
            })
            .catch(err => {
                if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw err
            })
    },
    getCount({ state, commit }) {

        return MajaalApi
            .index(`${state.apiPath}/count`)
            .then(response => {
                commit('SET_COUNT', response.data.count)
            })
            .catch(err => {
                if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw err
            })
    },
    updateItem({ commit, state }, { id, body }) {
        return MajaalApi.update(id, state.apiPath, body)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                if (error.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw error;
            });
    },
    quotations({ commit }, { body }) {
        return MajaalApi.store("quotations", body)
            .then((res) => {
                commit('SET_REPORT', res.data.data)
            })
            .catch((error) => {
                if (error.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw error;
            });
    },
}