import MajaalApi from "../../api/MajaalApi.js";

export const namespaced = true

export const state = {
	apiPath: 'items',
	items: [],
	item: {},
	meta: {},
	textures: [],
	dimensions: [],
	finishings: [],
	categories: [],
	similar: [],
	featured: [],
	mViewed: [],
	newest: [],
	downloadLink: {},
	filters: {
		range: [0, 1000],
		isAvailable: "",
		texture: [],
		finishing: [],
		dimension: [],
		categoryIds: [],
	}
}

export const mutations = {
	SET_FILTERS(state, value) {
		state.filters = { ...value };
	},
	SET_ITEMS(state, items) {
		state.items = items.data;
		state.meta = items.meta;
	},
	SET_FITEMS(state, items) {
		state.featured = items.data;
	},
	SET_MVIEWED(state, items) {
		state.mViewed = items.data;
	},
	SET_NEWEST(state, items) {
		state.newest = items.data;
	},
	SET_ITEM(state, item) {
		state.item = item.data;
	},
	SET_CATEGORIES(state, items) {
		state.categories = items.categories;
	},
	SET_TEXTURES(state, items) {
		state.textures = items.data;
	},
	SET_DIMENSIONS(state, items) {
		state.dimensions = items.data;
	},
	SET_FINISHINGS(state, items) {
		state.finishings = items.data;
	},
	SET_SIMILAR(state, items) {
		state.similar = items.data;
	},
	SET_DOWNLOAD_LINK(state, url) {
		state.downloadLink = url;
	},
}

export const actions = {

	getItems({ state, commit }, { filters }) {
		filters = { ...filters }

		return MajaalApi
			.index(state.apiPath, filters)
			.then(response => {
				commit('SET_ITEMS', response.data)
			})
			.catch(err => {
				console.log(err.response.status)
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},

	getItem({ state, commit }, { id }) {

		return MajaalApi
			.show(id, state.apiPath)
			.then(response => {
				commit('SET_ITEM', response.data)
			})
			.catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	getSimilar({ state, commit }, { id }) {

		return MajaalApi
			.show(`${id}/similar`, state.apiPath)
			.then(response => {
				commit('SET_SIMILAR', response.data)
			})
			.catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	getFeatured({ commit }) {

		return MajaalApi
			.index(`featured_items`)
			.then(response => {
				commit('SET_FITEMS', response.data)
			})
			.catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	getMViewed({ commit }) {

		return MajaalApi
			.index(`most_viewed`)
			.then(response => {
				commit('SET_MVIEWED', response.data)
			})
			.catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	getNewest({ commit }) {

		return MajaalApi
			.index(`newest`)
			.then(response => {
				commit('SET_NEWEST', response.data)
			})
			.catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	addToFavorites({ commit, state }, { id }) {

		return MajaalApi
			.store(`${state.apiPath}/${id}/favorites`, {})
			.then(response => {
				return response;
			})
			.catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	addToCart({ commit, state }, { id }) {

		return MajaalApi
			.store(`${state.apiPath}/${id}/carts`, {})
			.then(response => {
				return response;
			})
			.catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	removeFromCart({ commit, state }, { id }) {

		return MajaalApi
			.delete(`${id}/carts`, state.apiPath)
			.then(response => {
				return response;
			})
			.catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	removeFromFavorites({ commit, state }, { id }) {

		return MajaalApi
			.delete(`${id}/favorites`, state.apiPath)
			.then(response => {
				return response;
			})
			.catch(err => {
				if (err.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw err
			})
	},
	downloadFile({ state, commit }, { id }) {
		console.log(state.apiPath);
		return MajaalApi.show(`${id}/download`, "item_files")
			.then((res) => {
				commit("SET_DOWNLOAD_LINK", res.data.data.url);
			})
			.catch((error) => {
				if (error.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw error;
			});
	},
	getTextures({ commit }) {
		return MajaalApi.index("items/textures")
			.then((res) => {
				commit("SET_TEXTURES", res.data);
			})
			.catch((error) => {
				if (error.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw error;
			});
	},
	getCategories({ commit }) {
		return MajaalApi.index("categories/all/recursively")
			.then((res) => {
				commit("SET_CATEGORIES", res.data);
			})
			.catch((error) => {
				if (error.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw error;
			});
	},
	getDimensions({ commit }) {
		return MajaalApi.index("items/dimensions")
			.then((res) => {
				commit("SET_DIMENSIONS", res.data);
			})
			.catch((error) => {
				if (error.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw error;
			});
	},
	getFinishings({ commit }) {
		return MajaalApi.index("items/finishing")
			.then((res) => {
				commit("SET_FINISHINGS", res.data);
			})
			.catch((error) => {
				if (error.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
				throw error;
			});
	},
}
