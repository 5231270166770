import MajaalApi from "../../api/MajaalApi";

export const namespaced = true;

export const state = {
    apiPath: "banners",
    p_banners: [],
    s_banners: [],
    banner: {},

};

export const mutations = {
    SET_PBANNERS(state, data) {
        state.p_banners = data.data.banners;
        state.meta = data.meta;
    },

    SET_SBANNERS(state, data) {
        state.s_banners = data.data.banners;
        state.meta = data.meta;
    },



};

export const actions = {
    getPrimaryBanners({ commit }) {
        return MajaalApi.index("banners?type=primary")
            .then((res) => {
                commit("SET_PBANNERS", res.data);
            })
            .catch((error) => {
                if (error.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw error;
            });
    },
    getSecondaryBanners({ commit }) {
        return MajaalApi.index("banners?type=secondary")
            .then((res) => {
                commit("SET_SBANNERS", res.data);
            })
            .catch((error) => {
                if (error.response.status == 401) commit('authModule/LOGOUT_USER', null, { root: true });
                throw error;
            });
    },



};
