<template>
  <div :class="`${$vuetify.breakpoint.width <= 400 ? 'mx-n7' : 'mx-n3'}`">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="0"
          :ripple="false"
          plain
          text
          style="letter-spacing: 0px"
        >
          {{ currentLang }} <v-icon> mdi-chevron-down</v-icon></v-btn
        >
      </template>
      <v-list>
        <v-list-item @click="setLocale('en')">
          <v-list-item-title>English</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setLocale('ar')">
          <v-list-item-title>العربية</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>


<script>
export default {
  computed: {
    currentLang() {
      return this.$i18n.locale == "en" ? "English" : "العربية";
    },
  },
  methods: {
    setLocale(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("lang", this.$i18n.locale);
    },
  },
};
</script>