<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="50%" scrollable>
      <v-card>
        <v-card-title>
          <v-btn fab icon class="mb-1" color="black" top right @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text height="500px">
          <div class="px-8">
            <v-carousel
              show-arrows-on-hover
              hide-delimiter-background
              interval="2000"
              :reverse="$i18n.locale == 'ar' ? true : false"
              continuous
              cycle
              height="400px"
            >
              <v-carousel-item
                style="cursor: pointer"
                @click="window.open(ad.url)"
                v-for="(ad, i) in ads"
                :key="i"
                :src="`https://majaal-system-files.ams3.cdn.digitaloceanspaces.com/${ad.imageUrl}`"
              ></v-carousel-item>
            </v-carousel>
          </div>
        </v-card-text>
        <v-card-actions class="py-7"></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["dialog"],

  computed: {
    ...mapState({
      ads: (state) => state.authModule.ads,
    }),
  },

  methods: {},
};
</script>
