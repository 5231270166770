import axios from 'axios';

const baseDomain = 'https://mvs-api.majaal.ly';
// const baseDomain = 'http://127.0.0.1:3333';
const baseUrl = `${baseDomain}/api/v1/`;

export const MajaalApiAxios = axios.create({
	baseURL: baseUrl,
});

export default {
	baseDomain: baseDomain,
	index(path, filters = {}, headers = {
		'Authorization': localStorage.getItem("MajaalToken")
	}) {
		//setup filters to become {'key': value, 'key': value}
		const params = {};
		const filtersKeys = Object.keys(filters);
		filtersKeys.forEach(key => {
			if (filters[key] == null || filters[key] === '') return;
			params[key] = filters[key];
		});

		return MajaalApiAxios.get(path, { params: params, headers: headers })
			.then(response => {

				if (response?.status && response.status === 401) {
					throw response;
				}
				return response;
			})
			.catch(error => {
				console.error(error);
				throw error;
			});
	},


	store(path, data, filters = {}, headers = {
		'Authorization': localStorage.getItem("MajaalToken")

	}) {
		//setup filters to become {'key': value, 'key': value}
		const params = {};
		const filtersKeys = Object.keys(filters);
		filtersKeys.forEach(key => {
			if (filters[key] == null || filters[key] === '') return;
			params[key] = filters[key];
		});

		//setup data to become {'key': value, 'key': value}
		const body = {};
		const dataKeys = Object.keys(data);
		dataKeys.forEach(key => {
			if (data[key] == null || data[key] === '') return;
			body[key] = data[key];
		});



		return MajaalApiAxios.post(path, body, { params: params, headers: headers })
			.then(response => {

				return response;
			})
			.catch(error => {
				console.error(error);
				throw error;
			});
	},

	show(id, path, filters = {}, headers = {
		'Authorization': localStorage.getItem("MajaalToken")

	}) {
		//setup filters to become {'key': value, 'key': value}
		const params = {};
		const filtersKeys = Object.keys(filters);
		filtersKeys.forEach(key => {
			if (filters[key] == null || filters[key] === '') return;
			params[key] = filters[key];
		});

		path = `${path}/${id}`;
		return MajaalApiAxios.get(path, { params: params, headers: headers })
			.then(response => {
				if (response.data?.error && response.data.error === true) {
					throw response;
				}
				return response;
			})
			.catch(error => {
				console.error(error);
				throw error;
			});
	},

	update(id, path, data, filters = {}, headers = {
		'Authorization': localStorage.getItem("MajaalToken")

	}) {
		//setup filters to become {'key': value, 'key': value}
		const params = {};
		const filtersKeys = Object.keys(filters);
		filtersKeys.forEach(key => {
			if (filters[key] == null || filters[key] === '') return;
			params[key] = filters[key];
		});

		//setup data to become {'key': value, 'key': value}
		const body = {};
		const dataKeys = Object.keys(data);
		dataKeys.forEach(key => {
			if (data[key] == null || data[key] === '') return;
			body[key] = data[key];
		});

		path = `${path}/${id}`;

		return MajaalApiAxios.put(path, body, { params: params, headers: headers })
			.then(response => {
				if (response.data?.error && response.data.error === true) {
					throw response;
				}
				return response;
			})
			.catch(error => {
				console.error(error);
				throw error;
			});
	},



	delete(id, path, filters = {}, headers = {
		'Authorization': localStorage.getItem("MajaalToken")

	}) {
		//setup filters to become {'key': value, 'key': value}
		const params = {};
		const filtersKeys = Object.keys(filters);
		filtersKeys.forEach(key => {
			if (filters[key] == null || filters[key] === '') return;
			params[key] = filters[key];
		});

		path = `${path}/${id}`;
		return MajaalApiAxios.delete(path, { params: params, headers: headers })
			.then(response => {
				if (response.data?.error && response.data.error === true) {
					throw response;
				}
				return response;
			})
			.catch(error => {
				console.error(error);
				throw error;
			});
	},



	downloadFile(
		path,
		filters = {},
		fileName = '1',
		fileType = 'application/pdf',
		extention = 'pdf',
		headers = {
			'Authorization': localStorage.getItem("MajaalToken")

		}
	) {
		//setup filters to become {'key': value, 'key': value}
		const params = {};
		const filtersKeys = Object.keys(filters);
		filtersKeys.forEach(key => {
			if (filters[key] == null || filters[key] === '') return;
			params[key] = filters[key];
		});

		return MajaalApiAxios.get(path, {
			params: params,
			responseType: 'blob',
			headers: headers,
		})
			.then(response => {
				if (response.data?.error && response.data.error === true) {
					throw response;
				}
				const blob = new Blob([response.data], {
					type: response?.headers['content-type']
						? response?.headers['content-type']
						: fileType,
				});
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = `${fileName}.${extention}`;
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				throw err;
			});
	},


};
