<template>
  <v-fab-transition>
    <a href="https://wa.me/qr/MT7ZCKKBMYZCA1" target="_blank">
      <v-btn
        color="success"
        fab
        large
        dark
        bottom
        :left="$i18n.locale == 'ar'"
        :right="$i18n.locale == 'en'"
        fixed
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
    </a>
  </v-fab-transition>
</template>

<script>
export default {};
</script>
