import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store"

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      auth: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Home/Home.vue"),
  },

  {
    path: "/items",
    name: "Items",
    meta: {
      auth: true,
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "items" */ "../views/Items/Items.vue"),
  },
  {
    path: "/cart",
    name: "Cart",
    meta: {
      auth: true,
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/Cart/Cart.vue"),
  },
  {
    path: "/favorites",
    name: "Favorites",
    meta: {
      auth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "favorites" */ "../views/Favorites/Favorites.vue"),
  },
  {
    path: "/favorites/collection/:id",
    name: "Collection",
    props: true,
    meta: {
      auth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "collection" */ "../views/Favorites/Components/Collection.vue"),
  },
  {
    path: "/items/:id",
    name: "Item",
    props: true,
    meta: {
      auth: true,

    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "items" */ "../views/Items/Components/Item.vue"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    meta: {
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (signup.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "resetPassword" */ "../views/ResetPassword/ResetPassword.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (signup.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login/Login.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    meta: {
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (signup.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/SignUp/SignUp.vue"),
  },

];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  // Requests needs to be called for every route change
  var loggedIn = localStorage.getItem("MajaalToken");
  
  if (loggedIn) {
    store.dispatch("authModule/validateToken");
    store.dispatch("cartModule/getCount", {});
    store.dispatch("favoritesModule/getCount", {});
  }

  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    if (from.path !== "/home") router.push("/home");
    else return;
  }
  if (to.name === "Login" && loggedIn) {
    if (from.path !== "/items") {
      router.push("/items");
    }

    else return;
  }
  if (to.name === "SignUp" && loggedIn) {
    if (from.path !== "/items") {
      router.push("/items");
    }
    else return;
  }
  if (to.name === "ResetPassword" && loggedIn) {
    if (from.path !== "/items") {
      router.push("/items");
    }
    else return;
  }
  if (to.name === "Home" && loggedIn) {
    if (from.path !== "/login") {
      router.push("/login");
    }
    else return;
  }

  next();
});

export default router;